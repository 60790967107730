import { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Button, Card, CardContent, Checkbox, FormLabel, Modal, ModalClose, ModalDialog, Radio, Sheet, Stack, Table, Textarea, Typography } from "@mui/joy";
import { api } from "../../services/api";
import { formatarMoeda } from "../../utils/moedas";
import { useMediaQuery } from '@mui/material';
import { toast } from 'react-toastify';

export default function DadosCotacao(props) {
    const mobile = useMediaQuery('(max-width:1200px)');

    const [modalCotacao, setModalCotacao] = useState(true);
    const [dadosCotacao, setDadosCotacao] = useState([]);
    const [cotacaoAlterada, setCotacaoAlterada] = useState(false);
    const [emRevisao, setEmRevisao] = useState(false);
    const [observacoesCotacao, setObservacoesCotacao] = useState("");
    const [observacoesMapa, setObservacoesMapa] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [dadosFornecedores, setDadosFornecedores] = useState([]);
    const [alteracoesMapaMCP1, setAlteracoesMapaMCP1] = useState([]);
    const [openModalRevisaoMCP1, setOpenModalRevisaoMCP1] = useState(false);

    //Temporário
    const obterCotacoes = async () => {
        const data = {
            CODCOTACAO: props.codCotacao,
            IDMOV06: props.numeroMov6,
            ETAPAATUAL: props.etapaAtual
        };

        const res = await api.post("/itensMapaCotacao",
            data,
            {
                headers: {
                    'Authorization': 'Bearer ' + props.token
                }
            }
        )
        console.log("itensmapacotacao");
        console.log(res.data.data);
        const consultaTratada = await tratarConsultaCotacao(res.data.data);
        setDadosCotacao(consultaTratada);

        // setDadosCotacao(res.data);
    }

    const tratarConsultaCotacao = async (data) => {
        let dadosTratados = [];

        //Verificando se existe alterações na cotação para serem exibidas
        const res = await consultarRevisaoCotacao();

        setAlteracoesMapaMCP1(res?.data?.data);

        const alteracoes = res?.data?.data || [];
        console.log("retorno alteracoes cotacao");
        console.log(alteracoes);
        //Transformando os dados do mobile em array

        setDadosFornecedores(data.MOBILE);

        console.log(alteracoes);
        setEmRevisao(alteracoes.length > 0);

        setObservacoesMapa(data[0].OBSERVACAOCOTACAO);
        setEmail(data[0].EMAIL);
        setTelefone(data[0].TELEFONE);

        let dataSemMobile = Object.values(data);
        dataSemMobile.splice(dataSemMobile.length - 1, 1);

        dataSemMobile.forEach((dados, indice) => {
            //Encontrando fornecedor ganhador
            let fornecedorGanhador = -1;
            let fornecedorMenorPreco = -1;
            let selecionado = -1;

            let itemPresenteNoBanco = false;

            if (alteracoes.length > 0) {
                setObservacoesCotacao(alteracoes[0].cotacaoObservacoes);
            }

            //Varrendo os fornecedores
            dados.FORNECEDORES.forEach((fornecedor, index) => {

                //Definindo fornecedor ganhador
                if (fornecedor.GANHADOR){
                    fornecedorGanhador = index;
                }
                if (parseFloat(fornecedor.VALNEGOCIADO) == dados.MENORVALOR) {
                    fornecedorMenorPreco = index;
                }
                //seta revisao mcp1
                if (fornecedor.REVISAO) {
                    selecionado = index;
                }
                //Alterando o fornecedor selecionado para o escolhido pelo MCP1
                if (alteracoes[indice]) {
                    // if (alteracoes.length > 0 && (fornecedor.CNPJ == alteracoes[indice].empresacnpj)) {
                    //     selecionado = index;
                    // }

                    //Na etapa de cotação exibir apenas os itens referentes aos itens salvos no banco
                    if (dados.CODIGOPRD == alteracoes[indice].codprd) {
                        itemPresenteNoBanco = true;
                    }
                }
            });

            //Adicionando o item para exibição apenas se ele existir no banco ou a etapa for mcp1
            // if (itemPresenteNoBanco || props.etapaAtual == "Aprov. mcp1") {
            //Se ja existe alterações na cotação e esta apenas consultando
            dadosTratados.push({
                PRODUTO: dados.NOMEFANTASIA,
                QUANT: dados.QUANTIDADE,
                CODUND: dados.CODUND,
                CODIGOPRD: dados.CODIGOPRD,
                MENORVALOR: dados.MENORVALOR,
                MARCAMODELO: dados.MARCAMODELO,

                FORNECEDORES: dados.FORNECEDORES,

                FORNECEDORGANHADOR: fornecedorGanhador,
                FORNECEDORMENORPRECO: fornecedorMenorPreco,
                IGNORADO: alteracoes[indice] ? alteracoes[indice].ignorar == 1 : false,
                SELECIONADO: selecionado != -1 ? selecionado : fornecedorGanhador
            });
            // }
        });

        verificarCotacaoAlterada();
        return dadosTratados;
    }

    useEffect(() => {
        obterCotacoes();
    }, []);

    const alterarFornecedor = (indiceCotacao, indiceFornecedor) => {
        // if (!emRevisao) {
        let copiaDadosCotacao = [...dadosCotacao];
        copiaDadosCotacao[indiceCotacao].SELECIONADO = indiceFornecedor;
        setDadosCotacao(copiaDadosCotacao);
        console.log(copiaDadosCotacao);
        verificarCotacaoAlterada();
        // }
    }

    const ignorarItem = (indiceCotacao) => {
        if (!emRevisao) {
            let copiaDadosCotacao = [...dadosCotacao];
            copiaDadosCotacao[indiceCotacao].IGNORADO = !copiaDadosCotacao[indiceCotacao].IGNORADO;
            setDadosCotacao(copiaDadosCotacao);
            verificarCotacaoAlterada();
        }
    }

    const verificarCotacaoAlterada = () => {
        let cotacaoAlterou = false;
        dadosCotacao.forEach(cotacao => {
            if ((cotacao.SELECIONADO != cotacao.FORNECEDORGANHADOR) || (cotacao.IGNORADO)) {
                cotacaoAlterou = true;
            }
        });
        setCotacaoAlterada(cotacaoAlterou);
    }

    const enviarParaRevisao = () => {
        //Preparando dados para o backend
        let cotacoesFormatadas = [];

        console.log("EnviarParaRevisao Json");
        console.log(dadosCotacao);

        dadosCotacao.forEach((cotacao, indice) => {

            cotacoesFormatadas.push({
                cotacao_id: props.idCotacao,
                ignorar: cotacao.IGNORADO,
                codprd: cotacao.CODIGOPRD,
                empresacnpj: cotacao.FORNECEDORES[cotacao.SELECIONADO].CNPJ,
                cotacaoObservacoes: observacoesCotacao,
                email: email,
                telefone: telefone,
                nomeProduto: cotacao.PRODUTO,
                nomeEmpresa: cotacao.FORNECEDORES[cotacao.SELECIONADO].RAZAOSOCIAL,
                valorProduto: cotacao.FORNECEDORES[cotacao.SELECIONADO]?.VALNEGOCIADO,
            });
        });


        api.post("/cotacaoItens",
            JSON.stringify(cotacoesFormatadas),
            {
                headers: {
                    'Authorization': 'Bearer ' + props.token
                }
            }
        ).then(res => {

            const data = {
                coligada: 1,
                idmov: props.numeroMov6
                // idmov: 2082163
            }
            //Retornando o processo para etapa de cotação

            props.movimentarProcesso("Reprovado");

        }).catch(err => {
            console.error(err);
            toast.error(JSON.stringify(err.response.data));
        });
    }

    const consultarRevisaoCotacao = async () => {
        const res = await api.get('/cotacaoItens/' + props.idCotacao,
            {
                headers: {
                    'Authorization': 'Bearer ' + props.token
                }
            }
        );
        return res;
    }

    const ignorarItemMobile = (newValue, index) => {
        const dadosCotacaoCopia = [...dadosCotacao];
        dadosCotacaoCopia[index].IGNORADO = newValue;
        setDadosCotacao(dadosCotacaoCopia);
        verificarCotacaoAlterada();
    }

    return (
        <>
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={modalCotacao}
            onClose={() => [setModalCotacao(false), props.setExibeMapaCotacao(false)]}
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                outline: "none",
            }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    width: mobile ? "calc(100% - 70px)" : "calc(100% - 100px)",
                    height: mobile ? "calc(100% - 70px)" : "calc(100% - 100px)",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    borderRadius: 'md',
                    p: props.mobile ? 2 : 3,
                    boxShadow: 'lg',
                    outline: "none",
                    backgroundColor: "#f0f0f0"
                }}
            >
                <ModalClose
                    variant="outlined"
                    sx={{
                        top: "calc(-1/4 * var(--IconButton-size))",
                        right: "calc(-1/4 * var(--IconButton-size))",
                        boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                        borderRadius: "50%",
                        bgcolor: "background.surface",
                    }}
                />
                <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                >
                    Dados da Cotação
                </Typography>

                {dadosCotacao.length > 0 ?
                    <Stack height={'95%'} overflow={"auto"}>
                        <Stack flexDirection={mobile ? "column" : "row"} alignItems={mobile ? "flex-start" : "center"} mb="10px">
                            <Typography fontWeight={"bold"}>Legenda:</Typography>
                            <Stack flexDirection={"row"} alignItems={"center"} ml={mobile ? "0px" : "20px"} mt={mobile ? "5px" : "0px"}>
                                <Stack ml={mobile ? "0px" : "10px"} width="18px" height="18px" backgroundColor="rgba(33, 150, 243, 0.4)"></Stack>
                                <Typography sx={{ marginLeft: "5px", fontSize: "14px" }}>Decisão do Comprador</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems={"center"} ml={mobile ? "0px" : "10px"}>
                                <Stack ml={mobile ? "0px" : "10px"} width="18px" height="18px" backgroundColor="rgb(255, 224, 130)"></Stack>
                                <Typography sx={{ marginLeft: "5px", fontSize: "14px" }}>Alterações</Typography>
                            </Stack>

                            <Stack flexDirection={"row"} alignItems={"center"} ml={mobile ? "0px" : "10px"}>
                                <Typography sx={{ marginLeft: "5px", fontSize: "14px" }}><Button variant="outlined" style={{margin: "5px"}} onClick={()=>setOpenModalRevisaoMCP1(true)}>Ver Revisão do MCP1</Button></Typography>
                            </Stack>
                        </Stack>

                        {/* {console.log(dadosCotacao)} */}


                        {mobile ?

                            <Stack>
                                <AccordionGroup>
                                    {dadosCotacao[0].FORNECEDORES.map((fornecedor, indice) => {
                                        return (
                                            <Accordion key={indice}>
                                                <AccordionSummary sx={{ marginBottom: 1 }}>
                                                    <Stack>
                                                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>{fornecedor.RAZAOSOCIAL}</Typography>
                                                        <Typography sx={{ fontSize: 14 }}>Município: {fornecedor.MUNICIPIO} </Typography>
                                                        <Typography sx={{ fontSize: 14 }}>Contato:  {fornecedor.CONTATO} </Typography>
                                                        <Typography sx={{ fontSize: 14 }}>Telefone:  {fornecedor.TELEFONE.replace(/^(\d{2})(\d{4}|\d{5})(\d{4})$/g, '($1) $2-$3')}</Typography>
                                                        <Typography sx={{ fontSize: 14 }}>Tipo:  {fornecedor.TIPO_FOR}</Typography>
                                                    </Stack>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ border: "1px dotted #888", borderRadius: 10 }}>
                                                    {dadosFornecedores[fornecedor.FORNECEDOR].map((item, index) => {
                                                        return (
                                                            <Stack key={index}>
                                                                <Stack bgcolor={dadosCotacao[index].FORNECEDORGANHADOR == indice ? "rgba(33, 150, 243, 0.8)" : dadosCotacao[index].SELECIONADO == indice ? "rgb(176, 129, 0)" : "#333"} p={2} mb={2} borderRadius={5} mt="10px">
                                                                    <Stack mb="10px">
                                                                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                                                            <Checkbox checked={dadosCotacao[index].IGNORADO} onChange={(e) => ignorarItemMobile(e.target.checked, index)} label="Ignorar" sx={{ color: "#eee", fontSize: 14 }} />
                                                                            {!dadosCotacao[index].IGNORADO && <Checkbox checked={dadosCotacao[index].SELECIONADO == indice} onChange={e => alterarFornecedor(index, indice)} label="Selecionar" sx={{ color: "#eee", fontSize: 14 }} />}
                                                                        </Stack>
                                                                        <Typography fontWeight={"bold"} mt="5px" sx={dadosCotacao[index].IGNORADO ? { color: "#eee", textDecoration: "line-through" } : { color: "#eee" }}>{item.NOMEFANTASIA}</Typography>
                                                                    </Stack>
                                                                    <Typography sx={{ color: "#eee" }}>Qtd: {item.QUANTIDADE}</Typography>
                                                                    <Typography sx={{ color: "#eee" }}>Und: {item.CODUND}</Typography>
                                                                    <Typography sx={{ color: "#eee" }} fontWeight={"bold"}>Marca / Modelo: {fornecedor.MARCAMODELO}</Typography>
                                                                    <Typography sx={{ color: "#eee" }} fontWeight={"bold"}>Valor Inicial: {formatarMoeda(item.VALCOTACAO)}</Typography>
                                                                    <Typography sx={{ color: "#eee" }} fontWeight={"bold"}>Valor Final: {formatarMoeda(item.VALNEGOCIADO)}</Typography>
                                                                </Stack>
                                                                {(dadosCotacao.length) - 1 == index &&
                                                                    <Stack>
                                                                        <Typography sx={{ fontSize: 14 }}>Total Bruto dos Produtos: <Typography sx={{ color: "#4caf50", fontWeight: "bold" }}>{formatarMoeda(dadosCotacao.reduce(
                                                                            (accumulator, item) => accumulator + (fornecedor.VALNEGOCIADO * item.QUANT),
                                                                            0,
                                                                        ))}</Typography></Typography>
                                                                        <Typography sx={{ fontSize: 14 }}>Descontos (-): <Typography sx={{ color: "#4caf50", fontWeight: "bold" }}>{formatarMoeda(fornecedor.DESCONTO)}</Typography></Typography>
                                                                        <Typography sx={{ fontSize: 14 }}>Despesas Acessórias (+): <Typography sx={{ color: "#4caf50", fontWeight: "bold" }}>{formatarMoeda(fornecedor.DESPESA)}</Typography></Typography>
                                                                        <Typography sx={{ fontSize: 14 }}>TOTAL LÍQUIDO: <Typography sx={{ color: "#4caf50", fontWeight: "bold" }}>{formatarMoeda(dadosCotacao.reduce(
                                                                            (accumulator, item) => accumulator + (fornecedor.VALNEGOCIADO * item.QUANT),
                                                                            0,
                                                                        ) - fornecedor.DESCONTO + fornecedor.DESPESA)}</Typography></Typography>
                                                                        <Typography sx={{ fontSize: 14 }}>Condição de Pagamento: <Typography sx={{ color: "#4caf50", fontWeight: "bold" }}></Typography>{fornecedor.CONDPGTO}</Typography>
                                                                        <Typography sx={{ fontSize: 14 }}>Data de Entrega: {fornecedor.DATAENTREGA}</Typography>
                                                                        <Typography sx={{ fontSize: 14 }}>Tipo de Frete: {fornecedor.FRETE}</Typography>
                                                                    </Stack>
                                                                }
                                                            </Stack>
                                                        );
                                                    })}
                                                </AccordionDetails>
                                            </Accordion>
                                        );
                                    })}
                                </AccordionGroup>
                                <Stack bgcolor={"#ddd"} mt={3} p={1} borderRadius={5}>
                                    <Typography fontWeight={"bold"}>Menor Preço Total</Typography>
                                    <Typography>

                                        {formatarMoeda(dadosCotacao.reduce(
                                            (accumulator, item) => {
                                                if (item.FORNECEDORES && item.FORNECEDORMENORPRECO !== undefined && item.FORNECEDORES[item.FORNECEDORMENORPRECO]) {
                                                    return accumulator + (item.FORNECEDORES[item.FORNECEDORMENORPRECO].VALNEGOCIADO * item.QUANT);
                                                }
                                                return accumulator;
                                            },
                                            0,
                                        ))}
                                    </Typography>
                                </Stack>
                            </Stack>
                            :
                            <Stack>
                                <Table borderAxis="both" style={{ width: 'unset' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: 'none', width: "355px" }} colSpan={4}></th>
                                            {dadosCotacao[0].FORNECEDORES.map((fornecedor, indice) =>
                                                <th colSpan={3} style={{ backgroundColor: "#333", color: "#eee", fontSize: "12px", width: "150px", lineHeight: '1.8', textAlign: "center", borderTopLeftRadius: !indice && 'var(--TableCell-cornerRadius, var(--unstable_actionRadius))' }}>
                                                    {<div style={{ marginBottomBottom: '1px', width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{fornecedor.RAZAOSOCIAL}</div>}<br />
                                                    {fornecedor.MUNICIPIO}<br />
                                                    {fornecedor.CONTATO}<br />
                                                    {fornecedor.TELEFONE.replace(/^(\d{2})(\d{4}|\d{5})(\d{4})$/g, '($1) $2-$3')}<br />
                                                    {fornecedor.TIPO_FOR}<br />
                                                </th>
                                            )}
                                            <th rowSpan={2} style={{ backgroundColor: "#333", color: "#eee", fontSize: "14px", width: "50px", alignContent: "center", textAlign: "center" }}>Menor Preço<br />Total</th>
                                        </tr>
                                        <tr>
                                            <th style={{ backgroundColor: "#333", color: "#eee", fontSize: "12px", width: "25px" }}>IGNORAR</th>
                                            <th style={{ backgroundColor: "#333", color: "#eee", fontSize: "12px", width: "280px" }}>ITENS / FORNECEDORES</th>
                                            <th style={{ backgroundColor: "#333", color: "#eee", fontSize: "12px", width: "20px", textAlign: "center" }}>QTD</th>
                                            <th style={{ backgroundColor: "#333", color: "#eee", fontSize: "12px", width: "20px", textAlign: "center" }}>UND</th>
                                            {dadosCotacao[0].FORNECEDORES.map(_ => (
                                                <>
                                                    <th style={{ backgroundColor: "#333", color: "#eee", fontSize: "12px", width: "120px", textAlign: "center" }}>MARCA / MODELO</th>
                                                    <th style={{ backgroundColor: "#333", color: "#eee", fontSize: "12px", width: "180px", textAlign: "center", borderTopRightRadius: '0px' }}>PREÇO INICIAL</th>
                                                    <th style={{ backgroundColor: "#333", color: "#eee", fontSize: "12px", width: "180px", textAlign: "center", borderTopRightRadius: '0px' }}>PREÇO FINAL</th>
                                                </>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dadosCotacao.map((item, indice) => (
                                            <tr key={indice} style={item.IGNORADO ? { textDecoration: "line-through", backgroundColor: "#ddd" } : {}}>
                                                <td style={{ textAlign: "center" }}><Checkbox disabled={!props.permissaoAprovador} checked={item.IGNORADO} onChange={() => ignorarItem(indice)} /></td>
                                                <td style={{ fontWeight: "bold", fontSize: "14px" }}>{item.PRODUTO}</td>
                                                <td style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }}>{item.QUANT}</td>
                                                <td style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center" }}>{item.CODUND}</td>

                                                {item.FORNECEDORES.map((fornecedor, index) => (
                                                    <>
                                                        <td>
                                                            <Typography sx={{ marginLeft: "5px", fontSize: "16px" }}>{item.MARCAMODELO[index].MARCAMODELO}</Typography>
                                                        </td>
                                                        <td >
                                                            <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                                                <Typography sx={{ marginLeft: "5px", fontSize: "16px" }}>{formatarMoeda(fornecedor.VALCOTACAO)}</Typography>
                                                            </Stack>
                                                        </td>
                                                        <td onClick={() => props.permissaoAprovador && alterarFornecedor(indice, index)} className="hover" style={item.FORNECEDORGANHADOR == (index) ? { backgroundColor: 'rgba(33, 150, 243, 0.4)', width: "200px" } : item.SELECIONADO == (index) ? { backgroundColor: 'rgb(255, 224, 130)', width: "200px" } : { width: "200px" }}>
                                                            <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                                                <Typography sx={{ marginLeft: "5px", fontSize: "16px" }}>{formatarMoeda(fornecedor.VALNEGOCIADO)}</Typography>
                                                            </Stack>
                                                        </td>
                                                    </>
                                                ))}
                                                <td style={{ width: "200px" }}>
                                                    <Stack flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
                                                        <Typography sx={{ marginLeft: "5px", fontSize: "16px" }}>{formatarMoeda(item.MENORVALOR * item.QUANT)}</Typography>
                                                    </Stack>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr style={{ fontWeight: '600' }}>
                                            <td colSpan={4} style={{ textAlign: "right", paddingTop: '0px', paddingBottom: '0px' }}>
                                                Total Bruto dos Produtos<br />
                                                Descontos (-)<br />
                                                Despesas Acessórias (+)<br />
                                                TOTAL LÍQUIDO<br />
                                                Condição de Pagamento<br />
                                                Data de Entrega<br />
                                                Tipo de Frete<br />
                                            </td>
                                            {dadosCotacao[0].FORNECEDORES.map((fornecedor, index) => (<>
                                                <td colSpan={3} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                                                    <div style={{ textAlign: "right", width: "100%" }}>
                                                        {formatarMoeda(dadosCotacao.reduce(
                                                            (accumulator, item) => accumulator + (item.FORNECEDORES[index].VALNEGOCIADO * item.QUANT),
                                                            0,
                                                        ))}<br />
                                                        {formatarMoeda(fornecedor.DESCONTO)}<br />
                                                        {formatarMoeda(fornecedor.DESPESA)}<br />
                                                        {formatarMoeda(dadosCotacao.reduce(
                                                            (accumulator, item) => accumulator + (item.FORNECEDORES[index].VALNEGOCIADO * item.QUANT),
                                                            0,
                                                        ) - fornecedor.DESCONTO + fornecedor.DESPESA)}<br />
                                                    </div>
                                                    <div style={{ textAlign: "center", width: "100%" }}>
                                                        {fornecedor.CONDPGTO}<br />
                                                        {fornecedor.DATAENTREGA}<br />
                                                        {fornecedor.FRETE}<br />
                                                    </div>
                                                </td>
                                            </>))}
                                            <td style={{ textAlign: "right", alignContent: 'start', paddingTop: '0px', paddingBottom: '0px' }}>

                                                {formatarMoeda(dadosCotacao.reduce(
                                                    (accumulator, item) => {
                                                        if (item.FORNECEDORES && item.FORNECEDORMENORPRECO !== undefined && item.FORNECEDORES[item.FORNECEDORMENORPRECO]) {
                                                            return accumulator + (item.FORNECEDORES[item.FORNECEDORMENORPRECO].VALNEGOCIADO * item.QUANT);
                                                        }
                                                        return accumulator;
                                                    },
                                                    0,
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Stack>
                        }

                        <Stack width="100%" marginTop={'10px'} flexDirection={mobile ? "column" : "row"} alignItems={mobile ? "flex-start" : "center"}>
                            <Stack flex={1} width={mobile ? "100%" : "auto"} mr="20px">
                                <FormLabel>Observações do Mapa:</FormLabel>
                                <Textarea disabled value={observacoesMapa} minRows={4} />
                            </Stack>
                            <Stack flex={1} width={mobile ? "100%" : "auto"} mt={mobile ? "10px" : "0px"}>
                                <FormLabel>Observações Técnicas:</FormLabel>
                                <Textarea disabled={!props.permissaoAprovador} value={observacoesCotacao} onChange={(e) => setObservacoesCotacao(e.target.value)} placeholder="Observações da cotação" minRows={4} />
                            </Stack>
                        </Stack>
                        {/* {!props.permissaoAprovador ?
                            <Stack mt="20px" flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                                <Button onClick={enviarParaRevisao} disabled={!cotacaoAlterada || !emRevisao} color="danger">Enviar para Revisão</Button>
                                <Button onClick={() => setModalCotacao(false)} disabled={cotacaoAlterada || !emRevisao} sx={{ marginLeft: "20px" }}>Prosseguir</Button>
                            </Stack>
                            : null
                        } */}
                        {props.permissaoAprovador ?
                            <Stack mt="20px" flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                                <Button onClick={enviarParaRevisao} disabled={!cotacaoAlterada} color="danger">Enviar para Revisão</Button>
                                <Button onClick={() => setModalCotacao(false)} disabled={cotacaoAlterada} sx={{ marginLeft: "20px" }}>Prosseguir</Button>
                            </Stack>
                            : null
                        }
                    </Stack>
                    :
                    <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
                        <Typography>Aguardando resposta do RM</Typography>
                    </Stack>
                }
            </Sheet>
        </Modal >

        <Modal open={openModalRevisaoMCP1}
            onClose={()=> setOpenModalRevisaoMCP1(false)}
            >
            <ModalDialog style={{overflow:"scroll"}}>
            <ModalClose/>
                <h3 style={{textAlign: "center"}}>Revisão do MCP1</h3>
            { alteracoesMapaMCP1.map((item, indice) => 
                <div style={{ backgroundColor: "#333333", marginBottom: "20px", padding: "18px", border: "1px dotted #778da9", borderRadius: "5px", textAlign: "center"}} key={indice}>
                    <Typography style={{color: "#fff", marginBottom: "10px", fontWeight: "bold", fontSize: "18px",textDecoration: item.ignorar? "line-through": ""}} maxWidth={"400px"}>{item.nomeEmpresa}</Typography>
                    <Typography style={{color: "#fff", textDecoration: item.ignorar? "line-through": ""}} maxWidth={"400px"}>{item.nomeProduto}</Typography>
                    <Typography style={{color: "#FFE082", fontWeight: "bold", textDecoration: item.ignorar? "line-through": ""}} maxWidth={"400px"}>{ formatarMoeda(item.valorProduto)}</Typography>
                    
                </div>
            )}
            { alteracoesMapaMCP1.length > 0 ?
                <><Typography style={{fontWeight: "bold"}}>Observações: </Typography> <Typography maxWidth={"400px"}>{alteracoesMapaMCP1[0].cotacaoObservacoes}</Typography></>
                :null
            }
            </ModalDialog>
        </Modal>

        </>
    )
}