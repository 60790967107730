import { api } from '../../../../services/api';

import { React, useState, useEffect } from "react";

import {
    Button,
    Stack,
    Typography,
    Modal,
    ModalClose,
    Sheet,
    List,
    ListItem,
    ListItemButton,
    ListItemDecorator,
    ListItemContent,
    Autocomplete,
} from "@mui/joy";

import { FaUser, FaPlus, FaTrashAlt } from "react-icons/fa";

import axios from "axios";

import { toast } from 'react-toastify';

export default function UsuarioCentroCusto({usuarioConsultado, mobile, abrirModal, centrosCustos }) {

    const [usuarioCentroCustoObj, setUsuarioCentroCustoObj] = useState({id: '', centro_custo_id: '', usuario_id: ''});
    const [usuarioConsultadoObj, setUsuarioConsultadoObj] = useState(usuarioConsultado);

    const [centrosCustosDisponiveis, setCentrosCustosDisponiveis] = useState([]);
    const [centrosCustosArray, setCentrosCustosArray] = useState([]);

    const [centroCustoSelected, setCentroCustoSelected] = useState(null);

    const [mostrarModalUsuariosCentrosCustos, setMostrarModalUsuariosCentrosCustos] = useState(false);
    const handleMostrarModalUsuariosCentrosCustos = () => {
        setMostrarModalUsuariosCentrosCustos(!mostrarModalUsuariosCentrosCustos);
    };

    useEffect(() => {
        recarregaUsuarioRelacConsultado(usuarioConsultadoObj.id);
        listaCentrosCustos();
    }, []);

    const listaCentrosCustos = () => {
        api.get("/centroCustos/")
        .then((response) => {
            //filtrar centros de custos para nao ter repetidos:
            const _centrosCustos = response.data.data;
            const nomesUnicos = new Set();
            const centrosCustosUnicos = [];

            _centrosCustos.forEach((centroCusto) => {
                if (!nomesUnicos.has(centroCusto.nome)) {
                    nomesUnicos.add(centroCusto.nome);
                    centrosCustosUnicos.push(centroCusto);
                }
            });
            setCentrosCustosDisponiveis(centrosCustosUnicos);
        })
        .catch((err)=> {
            console.log("não há centros de custos disponíveis.");
            toast.error(JSON.stringify(err.response.data));
        })
    }

    const handleAutocompleteCentroCusto = (event, newValue) => {
        setCentroCustoSelected(newValue);
    };

    const recarregaUsuarioRelacConsultado = (usuario_id) => {

        api.get("/usuariosRelac/"+ usuario_id)
        .then((response) => {
            setUsuarioConsultadoObj(response.data.data);
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro recarregaUsuarioRelac : " + err);
            toast.error(JSON.stringify(err.response.data));
        });
    }

    const criaUsuarioCentroCusto = () => {
        // criar um novo registro de usuario-centro-custo
        const dadosUsuarioCentroCusto = {
            usuario_id: usuarioConsultadoObj.id,
            centro_custo_id: centroCustoSelected.id,
        }

        api.post("/usuariosCusto/", 
            dadosUsuarioCentroCusto)
            .then((response) => {
                toast.success("Centro de Custo vinculado ao usuário!");
                //recarrega o centro-custo para refazer a lista de usuários do mesmo:
                recarregaUsuarioRelacConsultado(usuarioConsultadoObj.id);
                setCentroCustoSelected(null);
            })
            .catch(error => {
                toast.error("Não foi possível vincular centro de custo ao usuário.");
                console.log("erro criaUsuarioCentroCusto " + JSON.stringify(error));
                toast.error(JSON.stringify(error.response.data));
            });
    }

    const removeUsuarioCentroCusto = (centrocusto_id) => {
        // url delete ordem de /centro-custo/usuario

        const confirmar_exclusao = window.confirm("Deseja remover o usuário deste centro de custo?");
        if (confirmar_exclusao){
            api.delete("/usuariosCusto/"+ centrocusto_id + "/"+ usuarioConsultadoObj.id)
            .then((response) => {
                toast.info("Centro de Custo desvinculado do usuário!");
                //recarrega o centro-custo para refazer a lista de usuários do mesmo:
                recarregaUsuarioRelacConsultado(usuarioConsultadoObj.id);
                setCentroCustoSelected(null);
            })
            .catch(error => {
                toast.error("Não foi possível desvincular o Centro de Custo do usuário.");
                console.log("erro removeUsuarioCentroCusto " + JSON.stringify(error));
                toast.error(JSON.stringify(error.response.data));
            });
        }
    }

    return (
        <>
        <Button onClick={handleMostrarModalUsuariosCentrosCustos} variant="plain">Ver centros de custos</Button>
        <Modal
            open={mostrarModalUsuariosCentrosCustos}
            onClose={() => setMostrarModalUsuariosCentrosCustos(false)}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    width: '80%',
                    maxWidth: 600,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                }}
            >
                <ModalClose
                    variant="outlined"
                    sx={{
                        top: 'calc(-1/4 * var(--IconButton-size))',
                        right: 'calc(-1/4 * var(--IconButton-size))',
                        boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                        borderRadius: '50%',
                        bgcolor: 'background.surface',
                    }}
                />
                <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                >
                    Usuário - {usuarioConsultadoObj.nome}
                </Typography>
                <Stack mt="30px">
                    <Stack width="100%" flexDirection={mobile ? "column" : "row"} justifyContent="space-between" alignItems="center">

                        <Autocomplete options={centrosCustosDisponiveis} getOptionLabel={option => option.codigo + " - " + option.nome} 
                            onChange={handleAutocompleteCentroCusto} value={centroCustoSelected}
                            style={mobile ? { width: "100%" } : { flex: 1, marginRight: "20px" }} />
                        
                        <Button onClick={criaUsuarioCentroCusto} startDecorator={<FaPlus size="20px" />} sx={mobile ? { margin: "10px 0 20px 0", width: "100%" } : {}}>Adicionar Centro de Custo</Button>
                    </Stack>
                    {usuarioConsultadoObj.centro_custo ?
                        <List sx={{ overflow: "auto", height: "70vh" }}>
                            {usuarioConsultadoObj.centro_custo.map(function (centroCusto) {
                                return (
                                    <ListItem>
                                        <ListItemButton>
                                            <ListItemDecorator><FaUser /></ListItemDecorator>
                                            <ListItemContent>{centroCusto.codigo} - { centroCusto.nome }</ListItemContent>
                                            <FaTrashAlt onClick={() => removeUsuarioCentroCusto(centroCusto.id)} size="20px" color="red" style={{ marginLeft: "10px" }} />
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })}
                        </List>
                        : null
                    }
                </Stack>
            </Sheet>
        </Modal>
        </>
    )

}